import '../sass/style.scss';

import { $, $$ } from './modules/bling';
import typeAhead from './modules/typeAhead';
import dateSearch from './modules/dateSearch';
import modal from './modules/modal';
import { toggleBookmark } from './modules/toggleBookmark.js';

typeAhead( $('.search') );
dateSearch( $('.datePicker') );
//modal( $('.content') );
document.on('DOMContentLoaded', () => {
    toggleBookmark();
});
document.on('DOMContentLoaded', () => {
    toggleSidebar();
});
