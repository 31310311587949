import axios from 'axios';
import dompurify from 'dompurify';

//need to create the HTML frunction to dsiplay on the pages
function dateResultsHTML(subjectLines) {
  return subjectLines.map(subjectline => {
    return `<tr>
          <td>${subjectline.date}</td>
          <td>${subjectline.from}</td>
          <td>${subjectline.domain}</td>
          <td>${subjectline.weekday}</td>
          <td align="center">${subjectline.subjectLine}</td>
          <td align="center">${subjectline.characterLength}</td>
          </tr>
    `;
  }).join('');
}



function dateSearch(date){
  if (!date) return;


  $(function() {
    $('input[name="datePicker"]').daterangepicker({
      opens: 'left'
      },
      function(start, end, label) {
        const sender = document.URL.split('/')[4].toString();
        const dateSearchResults = document.getElementById('date__results');
        const startFor = start.format('YYYY-MM-DD');
        const endFor = end.format('YYYY-MM-DD');
    //console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' ' +'From:'+ sender);
        const url = `/api/v1/dateSearch?qs=${startFor}&qe=${endFor}&qsndr=${sender}`
        //console.log(dateSearchResults);

        axios
          .get(`/api/v1/dateSearch?qs=${startFor}&qe=${endFor}&qsndr=${sender}`)
          .then(res => {
            if (res.data.length) {
            const html = res.data;
            console.log(html);
            dateSearchResults.innerHTML = dateResultsHTML(res.data);
          }
          })
        });
  });

}



export default dateSearch;
