function toggleBookmark() {
    const userElement = document.querySelector('.hidden[data-user-id]');
    const bookmarkBtn = document.querySelector('.bookmark-btn');
    if (!userElement || !bookmarkBtn) return;

    const userId = userElement.getAttribute('data-user-id');
    const emailId = window.location.href.split('/')[4];

    bookmarkBtn.addEventListener('click', function() {
        const icon = this.querySelector('i.fa-bookmark');
        const isSaved = icon.classList.contains('fa-solid');

        const text = this.querySelector('span');
        updateSaveStatus(isSaved ? 'unsave' : 'save', emailId, userId);
        //console.log(emailId)
        //console.log(userId)

        // Toggle button appearance
        icon.classList.toggle('fa-regular');
        icon.classList.toggle('fa-solid');
        text.textContent = isSaved ? '  Save to Collection' : '  Saved';
    });
}

function updateSaveStatus(action, emailId, userId) {
    const apiUrl = '/api/v1/collection/toggle-save';
    //console.log(action)
    //console.log(emailId)
    //console.log(userId)
    
    fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email_id: emailId, user: userId, action }),
    })
    .then(response => response.json())
    .then(data => {
        //console.log(`${action === 'save' ? 'Saved' : 'Unsaved'} item with email ID ${emailId}`);
    })
    .catch(error => console.error('Error:', error)); 
}

export { toggleBookmark };